.services {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
}

.service-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three cards per row */
    gap: 20px;
    /* Gap between cards */
    max-width: 1200px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .service-cards {
        grid-template-columns: 1fr;
        /* Single column layout for smaller screens */
    }
}