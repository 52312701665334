.footer {
  background-color: #282c34;
  color: white;
  padding: 40px 0;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section {
  flex: 1;
  text-align: left;
  margin-bottom: 40px;
}

.section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #61dafb;
}

.contactDetails {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contactDetails svg {
  font-size: 1.5rem;
  margin-right: 10px;
  vertical-align: middle;
}

.contactDetails p {
  font-size: 1rem;
  margin: 0;
  line-height: 1;
  transition: color 0.3s ease;
  cursor: pointer;
}

.contactDetails p:hover {
  color: #61dafb;
}

.contactDetails .location {
  color: white; /* Specific color for the location text */
  cursor: initial; /* Disable cursor for the location text */
  transition: none; /* Remove transition effect */
}

.socialIcons {
  text-align: left;
  margin-top: 20px;
}

.socialIcons a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.socialIcons svg {
  font-size: 1.5rem;
  margin-right: 10px;
  vertical-align: middle;
}

.socialIcons a:hover {
  color: #61dafb;
}

.linkList {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.linkList li {
  margin-bottom: 10px;
}

.linkList a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.linkList a:hover {
  color: #61dafb;
}

.copyright {
  margin-top: 40px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    max-width: fit-content;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }
}
