.requestServiceContainer {
  background-color: white;
  padding: 20px;
  margin: 40px auto; /* Add margin to top and bottom */
  border-radius: 8px;
  width: 50%; /* Set the width to 50% of the viewport */
  max-width: 600px; /* Limit the maximum width to ensure it's not too wide on larger screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for a professional look */
}

.form {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 8px;
  display: block; /* Ensure labels are displayed as block elements */
}

.input,
.select,
textarea {
  padding: 10px;
  font-size: 1rem;
  border: none; /* Remove default border */
  outline: none; /* Remove default focus outline */
  background-color: #f5f5f5; /* Light gray background */
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.button {
  padding: 12px 20px;
  background-color: #61dafb; /* Blue color */
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: larger;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #8bdbf5; /* Darker blue on hover */
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 4px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .requestServiceContainer {
      width: 80%; /* Reduce width on smaller screens */
      padding: 15px; /* Adjust padding */
  }
}
