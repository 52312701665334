.termsContainer {
    background-color: white;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .termsContent {
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  