/* Navbar.module.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding: 15px 20px;
  height: 80px;
}

.logo {
  display: flex;
  align-items: center;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.logoImg {
  height: 50px;
  width: auto;
}

.title {
  font-size: 2rem; /* Increased font size */
  font-weight: bold; /* Made the font bold */
  margin-left: 10px;
  line-height: 1;
}

.linksContainer {
  display: flex;
  align-items: center;
}

.link {
  font-size: 1.1rem;
  color: white;
  text-decoration: none;
  margin: 0 15px;
  line-height: 1.5;
  transition: color 0.3s ease;
}

.link:hover {
  color: #61dafb;
}

.highlight {
  background-color: #8bdbf5;
  color: #282c34;
  padding: 8px 16px;
  border-radius: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 20px;
}

.highlight:hover {
  background-color: #61dafb;
  color: #282c34;
}

.contactContainer {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .navbar {
    padding: 15px;
    height: auto;
    flex-direction: column;
    text-align: center;
  }

  .logo {
    margin-bottom: 10px;
  }

  .linksContainer {
    margin-top: 0px;
    margin-bottom: 25px;
  }

  .link {
    font-size: 1rem;
    margin: 0 10px;
  }

  .highlight {
    margin-top: 30px;
    padding: 6px 12px;
    font-size: 1rem;
  }

  .linksContainer {
    margin-bottom: 15px;
  }
}
