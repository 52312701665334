.thank-you-container {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    min-height: calc(100vh - 120px);
  }
  
  .thank-you-heading {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .thank-you-message {
    font-size: 1.2rem;
  }
  
  .thank-you-link {
    text-decoration: none;
    color: #007bff;
    margin-top: 20px;
    display: inline-block;
    font-size: 1.1rem;
  }
  