.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000; /* Black background */
  color: #fff; /* White text color */
}

.main-content {
  flex-grow: 1; /* Takes up remaining vertical space */
  padding: 1rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .App {
    padding: 0; /* Remove any default padding/margin for smaller screens */
  }

  .main-content {
    padding: 0.5rem; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 0.25rem; /* Further reduce padding for very small screens */
  }
}
