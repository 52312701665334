/* Reset and General Styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff; /* Example background color */
  color: #333333; /* Example text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f3f3f3; /* Example background color for code */
  padding: 2px 4px;
  border-radius: 4px;
  color: #333333; /* Example text color for code */
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Adjustments for smaller screens */
  body {
    font-size: 14px; /* Example: Decrease font size for better readability on smaller screens */
  }

  code {
    font-size: 0.9em; /* Example: Adjust code font size */
  }
}
