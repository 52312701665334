/* FAQ.module.css */

.faq {
    padding: 40px;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .faq h2 {
    text-align: center;
    color: #282c34;
    margin-bottom: 30px;
  }
  
  .faq-list {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .faq-item {
    background-color: #282c34; /* Dark background color */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }
  
  .faq-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .faq-item h3 {
    color: #61dafb;
    margin-bottom: 10px;
  }
  
  .faq-item p {
    color: #ddd; /* Light text color for better contrast */
  }
  