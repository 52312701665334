/* AboutUs.module.css */

.aboutUs {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.content {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.story, .mission, .values, .expertise {
  margin-bottom: 40px;
}

.story h2, .mission h2, .values h2, .expertise h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #282c34;
  text-align: center;
}

.story p, .mission p, .expertise p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}

.values ul {
  list-style-type: none;
  padding: 0;
}

.values li {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.values li strong {
  color: #61dafb;
}

.expertise p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}
