/* ServiceCard.module.css */

.service-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom: 20px;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .card-image {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .card-content p {
    font-size: 1rem;
    color: #666666;
    line-height: 1.6;
  }
  