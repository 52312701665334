/* Home Page */
.home {
  font-family: Arial, sans-serif;
}

/* Hero Section */
.hero {
  position: relative;
  width: 100%;
  padding-top: 30%;
  overflow: hidden;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/hero.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.heroText {
  color: white;
  max-width: 800px;
  padding: 20px;
  animation: fadeIn 2s ease-in-out;
}

.heroText h1 {
  font-size: 3rem;
  margin: 0;
}

.heroText p {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}

.highlightedText {
  color: #61dafb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hero {
    padding-top: 60vh;
  }

  .heroText {
    max-width: 100%;
    padding: 10px;
  }

  .heroText h1 {
    font-size: 2.2rem;
  }

  .heroText p {
    font-size: 1rem;
    margin: 8px 0 12px;
  }
}

/* Additional Content Section */
.additionalContent {
  background-color: #f0f0f0;
  color: #333;
  padding: 40px 20px;
  text-align: center;
}

.additionalContent .container {
  max-width: 1200px;
  margin: 0 auto;
}

.additionalContent h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.additionalContent p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.additionalContent .features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.additionalContent .feature {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #61dafb;
  border-radius: 10px;
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
}

.additionalContent .feature h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #61dafb;
}

.additionalContent .feature p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .additionalContent .feature {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}
