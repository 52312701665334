.contactContainer {
  background-color: white;
  padding: 20px;
  margin: 40px auto;
  border-radius: 8px;
  width: 50%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 8px;
  display: block;
}

.input,
.select,
.textarea {
  padding: 10px;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.textarea {
  resize: vertical;
}

.button {
  padding: 12px 20px;
  background-color: #61dafb;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: larger;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #8bdbf5;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 4px;
}

@media (max-width: 768px) {
  .contactContainer {
      width: 80%;
      padding: 15px;
  }
}
